/**
 * Returns the volunteer center of the website that the user is currently visiting.
 * Maybe this composable is a bit overkill if `fetchCurrentVolunteerCenter` also caches properly.
 * This whole thing is a bit reduntant I guess. But it seems to work.
 */
export const useCurrentCenter = async () => {
  const currentCenter = useState<VolunteerCenter>('current-center')
  const currentCenterProfile = useState<VolunteerCenterProfile>('current-center-profile')

  if (!currentCenter.value || !currentCenterProfile.value) {
    // fetchCurrentVolunteerCenter doesn't need the ID, because it's set on the header already.
    const { volunteerCenter: initialCenter, relationships }
      = await fetchCurrentVolunteerCenter()

    currentCenter.value = initialCenter.value
    currentCenterProfile.value = relationships.value.profile
  }

  const isHeumen = computed(() => currentCenter.value?.name.includes('Heumen'))

  return { currentCenter, currentCenterProfile, isHeumen }
}

import { default as __indexIUfKi05x4WMeta } from "/vercel/path0/layers/tairo/pages/__index.vue?macro=true";
import { default as _91_91slug_93_93JEdq5zRQnGMeta } from "/vercel/path0/.app/pages/[[slug]].vue?macro=true";
import { default as indexpRd9zR0gRlMeta } from "/vercel/path0/volunteerly/pages/activiteiten/index.vue?macro=true";
import { default as overzichtZ8lzvRXm1eMeta } from "/vercel/path0/volunteerly/pages/activiteiten/overzicht.vue?macro=true";
import { default as index_4528SAiEe01y8Meta } from "/vercel/path0/.app/pages/analytics/index-2.vue?macro=true";
import { default as indexTS7YDSsDxsMeta } from "/vercel/path0/.app/pages/analytics/index.vue?macro=true";
import { default as indexkE9U0V9u5FMeta } from "/vercel/path0/volunteerly/pages/applications/[id]/index.vue?macro=true";
import { default as indexxGxwkYrzWgMeta } from "/vercel/path0/volunteerly/pages/applications/index.vue?macro=true";
import { default as login_451PzktOBWuMrMeta } from "/vercel/path0/.app/pages/auth/login-1.vue?macro=true";
import { default as recoverDHSNCySTpTMeta } from "/vercel/path0/.app/pages/auth/recover.vue?macro=true";
import { default as reset389BRdnuMwMeta } from "/vercel/path0/.app/pages/auth/reset.vue?macro=true";
import { default as token5YDpd8Z7VVMeta } from "/vercel/path0/.app/pages/auth/token.vue?macro=true";
import { default as indexDOGt0SodpyMeta } from "/vercel/path0/.app/pages/confirm/index.vue?macro=true";
import { default as indexmGKQ2lMWSUMeta } from "/vercel/path0/volunteerly/pages/content/index.vue?macro=true";
import { default as menu_45itemsfeMD9jViX6Meta } from "/vercel/path0/volunteerly/pages/content/menu-items.vue?macro=true";
import { default as contentTavsgksC19Meta } from "/vercel/path0/.app/pages/content.vue?macro=true";
import { default as index56yHaQOcEwMeta } from "/vercel/path0/volunteerly/pages/conversations/index.vue?macro=true";
import { default as indexVHFQNfOucFMeta } from "/vercel/path0/volunteerly/pages/dashboard/index.vue?macro=true";
import { default as een_45op_45een_45hulpIFjwrhyJ5GMeta } from "/vercel/path0/volunteerly/pages/een-op-een-hulp.vue?macro=true";
import { default as _91_91token_93_93u7VSBYrSGIMeta } from "/vercel/path0/.app/pages/forms/[[token]].vue?macro=true";
import { default as gelderse_45handen_45coming_45soonBilOpwYOOFMeta } from "/vercel/path0/volunteerly/pages/gelderse-handen-coming-soon.vue?macro=true";
import { default as gelderse_45handenrslWXfSF6wMeta } from "/vercel/path0/volunteerly/pages/gelderse-handen.vue?macro=true";
import { default as indexwk9YNLKAguMeta } from "/vercel/path0/.app/pages/notificaties/index.vue?macro=true";
import { default as editIAlTlZPyAVMeta } from "/vercel/path0/.app/pages/notification_templates/[id]/edit.vue?macro=true";
import { default as indexSyvqXTaJnyMeta } from "/vercel/path0/.app/pages/notification_templates/index.vue?macro=true";
import { default as editFITNY3pYJwMeta } from "/vercel/path0/volunteerly/pages/organizations/[id]/edit.vue?macro=true";
import { default as index77NsS3hu5uMeta } from "/vercel/path0/volunteerly/pages/organizations/[id]/index.vue?macro=true";
import { default as movejsuXe8XfmTMeta } from "/vercel/path0/volunteerly/pages/organizations/[id]/move.vue?macro=true";
import { default as addP52rWBDGPPMeta } from "/vercel/path0/volunteerly/pages/organizations/add.vue?macro=true";
import { default as approvem8vSRTEV9BMeta } from "/vercel/path0/volunteerly/pages/organizations/approve.vue?macro=true";
import { default as indexlu3wSGqaurMeta } from "/vercel/path0/volunteerly/pages/organizations/index.vue?macro=true";
import { default as indexusZAFVDdNBMeta } from "/vercel/path0/volunteerly/pages/organizations/signup/index.vue?macro=true";
import { default as successRrbkml8PUiMeta } from "/vercel/path0/volunteerly/pages/organizations/signup/success.vue?macro=true";
import { default as partners4GDv9bgdLAMeta } from "/vercel/path0/volunteerly/pages/partners.vue?macro=true";
import { default as indexB7MJzNEvvpMeta } from "/vercel/path0/volunteerly/pages/profiel/index.vue?macro=true";
import { default as verwijderdrySgyFtWiWMeta } from "/vercel/path0/volunteerly/pages/profiel/verwijderd.vue?macro=true";
import { default as indexn3RRajCth4Meta } from "/vercel/path0/volunteerly/pages/profile-edit/index.vue?macro=true";
import { default as settingsE9CbXIxDbcMeta } from "/vercel/path0/volunteerly/pages/profile-edit/settings.vue?macro=true";
import { default as profile_45edit0oMFymvf4iMeta } from "/vercel/path0/.app/pages/profile-edit.vue?macro=true";
import { default as indexVAiiBGpc7wMeta } from "/vercel/path0/volunteerly/pages/profile-replies/index.vue?macro=true";
import { default as search7Y1cKse8QuMeta } from "/vercel/path0/volunteerly/pages/public/search.vue?macro=true";
import { default as contact_45personJVTxno0VUMMeta } from "/vercel/path0/volunteerly/pages/settings/contact-person.vue?macro=true";
import { default as design9iYFXq8tzrMeta } from "/vercel/path0/volunteerly/pages/settings/design.vue?macro=true";
import { default as footerdrmFjXTUSRMeta } from "/vercel/path0/volunteerly/pages/settings/footer.vue?macro=true";
import { default as indexjZuWh0s663Meta } from "/vercel/path0/volunteerly/pages/settings/index.vue?macro=true";
import { default as settingszwBuDWBhVEMeta } from "/vercel/path0/volunteerly/pages/settings.vue?macro=true";
import { default as indexsTiQiaYGmpMeta } from "/vercel/path0/volunteerly/pages/signup/index.vue?macro=true";
import { default as successOwaq1Q43PtMeta } from "/vercel/path0/volunteerly/pages/signup/success.vue?macro=true";
import { default as _91id_93DYC3hVSLlTMeta } from "/vercel/path0/volunteerly/pages/sociale-kaart/[id].vue?macro=true";
import { default as index13wWZwUDMkMeta } from "/vercel/path0/volunteerly/pages/sociale-kaart/index.vue?macro=true";
import { default as switchn38JyRw5LxMeta } from "/vercel/path0/volunteerly/pages/switch.vue?macro=true";
import { default as authkaSMnQT4WcMeta } from "/vercel/path0/.app/pages/test/auth.vue?macro=true";
import { default as datepicker2DaAkr5UulMeta } from "/vercel/path0/.app/pages/test/datepicker.vue?macro=true";
import { default as dupeI47ZtOXqSqMeta } from "/vercel/path0/.app/pages/test/dupe.vue?macro=true";
import { default as formkm7kcPhdf0Meta } from "/vercel/path0/.app/pages/test/form.vue?macro=true";
import { default as formbuildero2fxBjU3qNMeta } from "/vercel/path0/.app/pages/test/formbuilder.vue?macro=true";
import { default as sentryVCPZPlthOwMeta } from "/vercel/path0/.app/pages/test/sentry.vue?macro=true";
import { default as server_45errorzkZTzpIMvfMeta } from "/vercel/path0/.app/pages/test/server-error.vue?macro=true";
import { default as step_45form_452zOUvlFOLgwMeta } from "/vercel/path0/.app/pages/test/step-form-2.vue?macro=true";
import { default as step_45formbVuEsOyEdOMeta } from "/vercel/path0/.app/pages/test/step-form.vue?macro=true";
import { default as toasterdGo68mWjbTMeta } from "/vercel/path0/.app/pages/test/toaster.vue?macro=true";
import { default as user_45storeNZaKEdPvEJMeta } from "/vercel/path0/.app/pages/test/user-store.vue?macro=true";
import { default as usermbs7HBNeeAMeta } from "/vercel/path0/.app/pages/test/user.vue?macro=true";
import { default as uitloggenJpGYx4npziMeta } from "/vercel/path0/.app/pages/uitloggen.vue?macro=true";
import { default as add_role1rGOgsOd7sMeta } from "/vercel/path0/.app/pages/users/[id]/add_role.vue?macro=true";
import { default as edit2D4CWW5dzWMeta } from "/vercel/path0/.app/pages/users/[id]/edit.vue?macro=true";
import { default as indexfosjwbxWtaMeta } from "/vercel/path0/.app/pages/users/[id]/index.vue?macro=true";
import { default as adds9wtR2FJuZMeta } from "/vercel/path0/.app/pages/users/add.vue?macro=true";
import { default as indexdlFaUjo5BDMeta } from "/vercel/path0/.app/pages/users/index.vue?macro=true";
import { default as editLjOfR8W4PVMeta } from "/vercel/path0/volunteerly/pages/vacancies/[id]/edit.vue?macro=true";
import { default as indextVJSpSHU3PMeta } from "/vercel/path0/volunteerly/pages/vacancies/[id]/index.vue?macro=true";
import { default as addrZKEE65rncMeta } from "/vercel/path0/volunteerly/pages/vacancies/add.vue?macro=true";
import { default as indexlBmD0PXZ6hMeta } from "/vercel/path0/volunteerly/pages/vacancies/index.vue?macro=true";
import { default as indexF6e2XAjFIcMeta } from "/vercel/path0/volunteerly/pages/vacancies/search/index.vue?macro=true";
import { default as changelog64EQ8JmpEUMeta } from "/vercel/path0/volunteerly/pages/volunteerly/changelog.vue?macro=true";
import { default as indexhMPxD0M7zmMeta } from "/vercel/path0/volunteerly/pages/vrijwilligerspool/index.vue?macro=true";
import { default as indexRLNDVRJaRWMeta } from "/vercel/path0/volunteerly/pages/workshops/index.vue?macro=true";
import { default as overzicht5Kw5FcVsKRMeta } from "/vercel/path0/volunteerly/pages/workshops/overzicht.vue?macro=true";
export default [
  {
    name: "__index",
    path: "/__index",
    component: () => import("/vercel/path0/layers/tairo/pages/__index.vue")
  },
  {
    name: "slug",
    path: "/:slug?",
    meta: _91_91slug_93_93JEdq5zRQnGMeta || {},
    component: () => import("/vercel/path0/.app/pages/[[slug]].vue")
  },
  {
    name: "activiteiten",
    path: "/activiteiten",
    meta: indexpRd9zR0gRlMeta || {},
    component: () => import("/vercel/path0/volunteerly/pages/activiteiten/index.vue")
  },
  {
    name: "activiteiten-overzicht",
    path: "/activiteiten/overzicht",
    component: () => import("/vercel/path0/volunteerly/pages/activiteiten/overzicht.vue")
  },
  {
    name: "analytics-index-2",
    path: "/analytics/index-2",
    component: () => import("/vercel/path0/.app/pages/analytics/index-2.vue")
  },
  {
    name: "analytics",
    path: "/analytics",
    meta: indexTS7YDSsDxsMeta || {},
    component: () => import("/vercel/path0/.app/pages/analytics/index.vue")
  },
  {
    name: "applications-id",
    path: "/applications/:id()",
    component: () => import("/vercel/path0/volunteerly/pages/applications/[id]/index.vue")
  },
  {
    name: "applications",
    path: "/applications",
    component: () => import("/vercel/path0/volunteerly/pages/applications/index.vue")
  },
  {
    name: "auth-login-1",
    path: "/auth/login-1",
    meta: login_451PzktOBWuMrMeta || {},
    component: () => import("/vercel/path0/.app/pages/auth/login-1.vue")
  },
  {
    name: "auth-recover",
    path: "/auth/recover",
    meta: recoverDHSNCySTpTMeta || {},
    component: () => import("/vercel/path0/.app/pages/auth/recover.vue")
  },
  {
    name: "auth-reset",
    path: "/auth/reset",
    meta: reset389BRdnuMwMeta || {},
    component: () => import("/vercel/path0/.app/pages/auth/reset.vue")
  },
  {
    name: "auth-token",
    path: "/auth/token",
    component: () => import("/vercel/path0/.app/pages/auth/token.vue")
  },
  {
    name: "confirm",
    path: "/confirm",
    meta: indexDOGt0SodpyMeta || {},
    component: () => import("/vercel/path0/.app/pages/confirm/index.vue")
  },
  {
    name: contentTavsgksC19Meta?.name,
    path: "/content",
    component: () => import("/vercel/path0/.app/pages/content.vue"),
    children: [
  {
    name: "content",
    path: "",
    component: () => import("/vercel/path0/volunteerly/pages/content/index.vue")
  },
  {
    name: "content-menu-items",
    path: "menu-items",
    component: () => import("/vercel/path0/volunteerly/pages/content/menu-items.vue")
  }
]
  },
  {
    name: "conversations",
    path: "/conversations",
    component: () => import("/vercel/path0/volunteerly/pages/conversations/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/vercel/path0/volunteerly/pages/dashboard/index.vue")
  },
  {
    name: "een-op-een-hulp",
    path: "/een-op-een-hulp",
    meta: een_45op_45een_45hulpIFjwrhyJ5GMeta || {},
    component: () => import("/vercel/path0/volunteerly/pages/een-op-een-hulp.vue")
  },
  {
    name: "forms-token",
    path: "/forms/:token?",
    meta: _91_91token_93_93u7VSBYrSGIMeta || {},
    component: () => import("/vercel/path0/.app/pages/forms/[[token]].vue")
  },
  {
    name: "gelderse-handen-coming-soon",
    path: "/gelderse-handen-coming-soon",
    meta: gelderse_45handen_45coming_45soonBilOpwYOOFMeta || {},
    component: () => import("/vercel/path0/volunteerly/pages/gelderse-handen-coming-soon.vue")
  },
  {
    name: "gelderse-handen",
    path: "/gelderse-handen",
    meta: gelderse_45handenrslWXfSF6wMeta || {},
    component: () => import("/vercel/path0/volunteerly/pages/gelderse-handen.vue")
  },
  {
    name: "notificaties",
    path: "/notificaties",
    component: () => import("/vercel/path0/.app/pages/notificaties/index.vue")
  },
  {
    name: "notification_templates-id-edit",
    path: "/notification_templates/:id()/edit",
    component: () => import("/vercel/path0/.app/pages/notification_templates/[id]/edit.vue")
  },
  {
    name: "notification_templates",
    path: "/notification_templates",
    component: () => import("/vercel/path0/.app/pages/notification_templates/index.vue")
  },
  {
    name: "organizations-id-edit",
    path: "/organizations/:id()/edit",
    component: () => import("/vercel/path0/volunteerly/pages/organizations/[id]/edit.vue")
  },
  {
    name: "organizations-id",
    path: "/organizations/:id()",
    meta: index77NsS3hu5uMeta || {},
    component: () => import("/vercel/path0/volunteerly/pages/organizations/[id]/index.vue")
  },
  {
    name: "organizations-id-move",
    path: "/organizations/:id()/move",
    component: () => import("/vercel/path0/volunteerly/pages/organizations/[id]/move.vue")
  },
  {
    name: "organizations-add",
    path: "/organizations/add",
    component: () => import("/vercel/path0/volunteerly/pages/organizations/add.vue")
  },
  {
    name: "organizations-approve",
    path: "/organizations/approve",
    component: () => import("/vercel/path0/volunteerly/pages/organizations/approve.vue")
  },
  {
    name: "organizations",
    path: "/organizations",
    component: () => import("/vercel/path0/volunteerly/pages/organizations/index.vue")
  },
  {
    name: "organizations-signup",
    path: "/organizations/signup",
    meta: indexusZAFVDdNBMeta || {},
    component: () => import("/vercel/path0/volunteerly/pages/organizations/signup/index.vue")
  },
  {
    name: "organizations-signup-success",
    path: "/organizations/signup/success",
    meta: successRrbkml8PUiMeta || {},
    component: () => import("/vercel/path0/volunteerly/pages/organizations/signup/success.vue")
  },
  {
    name: "partners",
    path: "/partners",
    meta: partners4GDv9bgdLAMeta || {},
    component: () => import("/vercel/path0/volunteerly/pages/partners.vue")
  },
  {
    name: "profiel",
    path: "/profiel",
    component: () => import("/vercel/path0/volunteerly/pages/profiel/index.vue")
  },
  {
    name: "profiel-verwijderd",
    path: "/profiel/verwijderd",
    component: () => import("/vercel/path0/volunteerly/pages/profiel/verwijderd.vue")
  },
  {
    name: profile_45edit0oMFymvf4iMeta?.name,
    path: "/profile-edit",
    component: () => import("/vercel/path0/.app/pages/profile-edit.vue"),
    children: [
  {
    name: "profile-edit",
    path: "",
    meta: indexn3RRajCth4Meta || {},
    component: () => import("/vercel/path0/volunteerly/pages/profile-edit/index.vue")
  },
  {
    name: "profile-edit-settings",
    path: "settings",
    meta: settingsE9CbXIxDbcMeta || {},
    component: () => import("/vercel/path0/volunteerly/pages/profile-edit/settings.vue")
  }
]
  },
  {
    name: "profile-replies",
    path: "/profile-replies",
    component: () => import("/vercel/path0/volunteerly/pages/profile-replies/index.vue")
  },
  {
    name: "public-search",
    path: "/public/search",
    meta: search7Y1cKse8QuMeta || {},
    component: () => import("/vercel/path0/volunteerly/pages/public/search.vue")
  },
  {
    name: settingszwBuDWBhVEMeta?.name,
    path: "/settings",
    component: () => import("/vercel/path0/volunteerly/pages/settings.vue"),
    children: [
  {
    name: "settings-contact-person",
    path: "contact-person",
    component: () => import("/vercel/path0/volunteerly/pages/settings/contact-person.vue")
  },
  {
    name: "settings-design",
    path: "design",
    component: () => import("/vercel/path0/volunteerly/pages/settings/design.vue")
  },
  {
    name: "settings-footer",
    path: "footer",
    component: () => import("/vercel/path0/volunteerly/pages/settings/footer.vue")
  },
  {
    name: "settings",
    path: "",
    component: () => import("/vercel/path0/volunteerly/pages/settings/index.vue")
  }
]
  },
  {
    name: "signup",
    path: "/signup",
    meta: indexsTiQiaYGmpMeta || {},
    component: () => import("/vercel/path0/volunteerly/pages/signup/index.vue")
  },
  {
    name: "signup-success",
    path: "/signup/success",
    meta: successOwaq1Q43PtMeta || {},
    component: () => import("/vercel/path0/volunteerly/pages/signup/success.vue")
  },
  {
    name: "sociale-kaart-id",
    path: "/sociale-kaart/:id()",
    meta: _91id_93DYC3hVSLlTMeta || {},
    component: () => import("/vercel/path0/volunteerly/pages/sociale-kaart/[id].vue")
  },
  {
    name: "sociale-kaart",
    path: "/sociale-kaart",
    meta: index13wWZwUDMkMeta || {},
    component: () => import("/vercel/path0/volunteerly/pages/sociale-kaart/index.vue")
  },
  {
    name: "switch",
    path: "/switch",
    meta: switchn38JyRw5LxMeta || {},
    component: () => import("/vercel/path0/volunteerly/pages/switch.vue")
  },
  {
    name: "test-auth",
    path: "/test/auth",
    meta: authkaSMnQT4WcMeta || {},
    component: () => import("/vercel/path0/.app/pages/test/auth.vue")
  },
  {
    name: "test-datepicker",
    path: "/test/datepicker",
    meta: datepicker2DaAkr5UulMeta || {},
    component: () => import("/vercel/path0/.app/pages/test/datepicker.vue")
  },
  {
    name: "test-dupe",
    path: "/test/dupe",
    meta: dupeI47ZtOXqSqMeta || {},
    component: () => import("/vercel/path0/.app/pages/test/dupe.vue")
  },
  {
    name: "test-form",
    path: "/test/form",
    meta: formkm7kcPhdf0Meta || {},
    component: () => import("/vercel/path0/.app/pages/test/form.vue")
  },
  {
    name: "test-formbuilder",
    path: "/test/formbuilder",
    component: () => import("/vercel/path0/.app/pages/test/formbuilder.vue")
  },
  {
    name: "test-sentry",
    path: "/test/sentry",
    component: () => import("/vercel/path0/.app/pages/test/sentry.vue")
  },
  {
    name: "test-server-error",
    path: "/test/server-error",
    component: () => import("/vercel/path0/.app/pages/test/server-error.vue")
  },
  {
    name: "test-step-form-2",
    path: "/test/step-form-2",
    component: () => import("/vercel/path0/.app/pages/test/step-form-2.vue")
  },
  {
    name: "test-step-form",
    path: "/test/step-form",
    component: () => import("/vercel/path0/.app/pages/test/step-form.vue")
  },
  {
    name: "test-toaster",
    path: "/test/toaster",
    component: () => import("/vercel/path0/.app/pages/test/toaster.vue")
  },
  {
    name: "test-user-store",
    path: "/test/user-store",
    component: () => import("/vercel/path0/.app/pages/test/user-store.vue")
  },
  {
    name: "test-user",
    path: "/test/user",
    meta: usermbs7HBNeeAMeta || {},
    component: () => import("/vercel/path0/.app/pages/test/user.vue")
  },
  {
    name: "uitloggen",
    path: "/uitloggen",
    component: () => import("/vercel/path0/.app/pages/uitloggen.vue")
  },
  {
    name: "users-id-add_role",
    path: "/users/:id()/add_role",
    component: () => import("/vercel/path0/.app/pages/users/[id]/add_role.vue")
  },
  {
    name: "users-id-edit",
    path: "/users/:id()/edit",
    component: () => import("/vercel/path0/.app/pages/users/[id]/edit.vue")
  },
  {
    name: "users-id",
    path: "/users/:id()",
    component: () => import("/vercel/path0/.app/pages/users/[id]/index.vue")
  },
  {
    name: "users-add",
    path: "/users/add",
    meta: adds9wtR2FJuZMeta || {},
    component: () => import("/vercel/path0/.app/pages/users/add.vue")
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/vercel/path0/.app/pages/users/index.vue")
  },
  {
    name: "vacancies-id-edit",
    path: "/vacancies/:id()/edit",
    component: () => import("/vercel/path0/volunteerly/pages/vacancies/[id]/edit.vue")
  },
  {
    name: "vacancies-id",
    path: "/vacancies/:id()",
    meta: indextVJSpSHU3PMeta || {},
    component: () => import("/vercel/path0/volunteerly/pages/vacancies/[id]/index.vue")
  },
  {
    name: "vacancies-add",
    path: "/vacancies/add",
    component: () => import("/vercel/path0/volunteerly/pages/vacancies/add.vue")
  },
  {
    name: "vacancies",
    path: "/vacancies",
    component: () => import("/vercel/path0/volunteerly/pages/vacancies/index.vue")
  },
  {
    name: "vacancies-search",
    path: "/vacancies/search",
    meta: indexF6e2XAjFIcMeta || {},
    component: () => import("/vercel/path0/volunteerly/pages/vacancies/search/index.vue")
  },
  {
    name: "volunteerly-changelog",
    path: "/volunteerly/changelog",
    meta: changelog64EQ8JmpEUMeta || {},
    component: () => import("/vercel/path0/volunteerly/pages/volunteerly/changelog.vue")
  },
  {
    name: "vrijwilligerspool",
    path: "/vrijwilligerspool",
    component: () => import("/vercel/path0/volunteerly/pages/vrijwilligerspool/index.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: indexRLNDVRJaRWMeta || {},
    component: () => import("/vercel/path0/volunteerly/pages/workshops/index.vue")
  },
  {
    name: "workshops-overzicht",
    path: "/workshops/overzicht",
    component: () => import("/vercel/path0/volunteerly/pages/workshops/overzicht.vue")
  }
]